<template>
    <div class="StatSessionFilters">

        <DateRangePicker
            :label="$lng.__('lk_webmaster_main', 'Period')"
            ref="picker"
            opens="right"
            :locale-data="{
                        firstDay: 1,
                        format: 'dd.mm.yyyy',
                        daysOfWeek: $lng.__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat').split(','),
                        monthNames: $lng.__('lk_webmaster_main', 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec').split(','),
                    }"
            :singleDatePicker="false"
            :timePicker="false"
            :showDropdowns="true"
            :autoApply="true"
            :dateRange="filters.dateRange"
            v-model="filters.dateRange"
            @update="setDateRange"
            :linkedCalendars="false">
        </DateRangePicker>

        <FormSelectMulti
            :label="$lng.__('lk_webmaster_main', 'Stream')"
            :placeholder="$lng.__('lk_webmaster_main', 'Select stream')"
            v-model:selected="filters.byStream"
            :borderless=false
            :multiple=false
            :nested=false
            key="filterByStream"
            :options=filterByStreamOptions>
        </FormSelectMulti>

        <FormSelectMulti
            :label="$lng.__('lk_webmaster_main', 'Geo')"
            :placeholder="$lng.__('lk_webmaster_main', 'Select country')"
            v-model:selected="filters.byCountry"
            :borderless=false
            :multiple=true
            :nested=false
            key="filterByCountry"
            :options=filterByCountryOptions>
        </FormSelectMulti>

        <FormSelectMulti
            :label="$lng.__('lk_webmaster_main', 'Offer')"
            :placeholder="$lng.__('lk_webmaster_main', 'Choose an offer')"
            v-model:selected="filters.byOffer"
            :borderless=false
            :multiple=false
            :nested=false
            key="filterByOffer"
            :options=filterByOfferOptions>
        </FormSelectMulti>

        <FormSelect
            :label="$lng.__('lk_webmaster_main', 'Status')"
            :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
            :showResetOption="true"
            :options="filterByStatusOptions"
            v-model:selected="filters.byStatus"
        ></FormSelect>

        <FormInput
            :label="$lng.__('lk_webmaster_main', 'GUID')"
            :placeholder="$lng.__('lk_webmaster_main', 'Search by GUID')"
            v-model="filters.byGuid"
        ></FormInput>

        <FormInput
            label="UTM Content"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmContent"
        ></FormInput>

        <FormInput
            label="UTM Medium"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmMedium"
        ></FormInput>

        <FormInput
            label="UTM Campaign"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmCampaign"
        ></FormInput>

        <FormInput
            label="UTM Term"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmTerm"
        ></FormInput>

        <div class="StatSessionFilters__reset">
            <button
                v-if="isResetFiltersVisible"
                @click="resetFilters()"
                class="wv-btn--green wv-btn--text wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'Reset') }}
            </button>
        </div>
        <div class="StatSessionFilters__reset" v-if="tableLeadColumns > 0">
            <button class="wv-btn--green wv-btn--text wv-btn--block" @click="showCustomizeTable()">
                <SvgUse :width="16" :height="16" id="btnCustomizeTable"></SvgUse>
                <p>
                {{ $lng.__('lk_webmaster_main', 'Customize table') }}
                </p>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'StatLeadFilters',
    props: [
        'filters',
        'leadsLoaded',
        'tableLeadColumns'
    ],
    data() {
        return {
            filtersFromUrlAdded: false,
        };
    },
    emits: [
        'update:filters',
        'customizeTable'
    ],
    computed: {
        leads() {
            return collect(this.$store.state.webmaster.leads)
                .map(lead => {
                    lead.status = this.$lng.__('lk_webmaster_main', lead.status);
                    return lead;
                })
                .all();
        },
        filterByStreamOptions() {
            return collect(this.leads)
                .map(lead => {
                    return {
                        id: lead.stream.id,
                        name: lead.stream.name,
                    };
                })
                .unique()
                .all();
        },
        filterByCountryOptions() {
            return this.convertArrayToOptions(
                collect(this.leads)
                    .pluck('geo')
                    .filter(i => i !== null)
                    .all()
            );
        },
        filterByOfferOptions() {
            return collect(this.leads)
                .map(lead => {
                    return {
                        id: lead.offer.id,
                        name: lead.offer.name,
                    };
                })
                .unique(item => item.id)
                .all();
        },
        filterByStatusOptions() {
            return this.convertArrayToOptions(
                collect(this.leads)
                    .pluck('status')
                    .filter(i => i !== null)
                    .all()
            );
        },
        isResetFiltersVisible() {
            return this.filters.byStream.length > 0
                || this.filters.byCountry.length > 0
                || this.filters.byOffer.length > 0
                || this.filters.byStatus
                || this.filters.byGuid !== ''
                || this.filters.byUtmContent !== ''
                || this.filters.byUtmMedium !== ''
                || this.filters.byUtmCampaign !== ''
                || this.filters.byUtmTerm !== '';
        },
    },
    watch: {
        leadsLoaded(val, oldVal) {
            if (!this.filtersFromUrlAdded) {
                let filters = {...this.filters};

                if (val) {
                    // Применяем оффер из URL
                    if (WV.getUrlParamByName('offer') !== null) {
                        filters.byOffer.push(JSON.parse(WV.getUrlParamByName('offer')));
                    }

                    // Применяем поток из URL
                    if (WV.getUrlParamByName('stream') !== null) {
                        filters.byStream.push(JSON.parse(WV.getUrlParamByName('stream')));
                    }

                    // Применяем страну из URL
                    if (WV.getUrlParamByName('country') !== null) {
                        let country = WV.getUrlParamByName('country');
                        filters.byCountry = [];
                        filters.byCountry.push({id: country, name: country});
                    }

                    // Применяем UTM из URL
                    if (WV.getUrlParamByName('utm_content') !== null) {
                        filters.byUtmContent = WV.getUrlParamByName('utm_content');
                    }
                    if (WV.getUrlParamByName('utm_medium') !== null) {
                        filters.byUtmMedium = WV.getUrlParamByName('utm_medium');
                    }
                    if (WV.getUrlParamByName('utm_campaign') !== null) {
                        filters.byUtmCampaign = WV.getUrlParamByName('utm_campaign');
                    }
                    if (WV.getUrlParamByName('utm_term') !== null) {
                        filters.byUtmCampaign = WV.getUrlParamByName('utm_term');
                    }

                    this.filtersFromUrlAdded = true;

                    this.$emit('update:filters', filters);
                }
            }
        },
    },
    methods: {
        convertArrayToOptions(array) {
            return collect(array)
                .flatten()
                .unique()
                .map(item => {
                    return {id: item, name: item};
                })
                .all();
        },
        applyDateRangeFromUrl() {
            let filters = {...this.filters};

            // Применяем диапазон дат из URL
            if (WV.getUrlParamByName('startDate') !== null && WV.getUrlParamByName('endDate') !== null) {
                filters.dateRange = {
                    startDate: new Date(WV.getUrlParamByName('startDate')),
                    endDate: new Date(WV.getUrlParamByName('endDate'))
                };
            } else {
                filters.dateRange = {
                    startDate: (new Date),
                    endDate: (new Date)
                };
            }

            this.$emit('update:filters', filters);
        },
        resetFilters() {
            this.$router.push({path: '/'+this.$route.params.lng+'/webmaster/stat/lead'});
            this.filters.byStream = '';
            this.filters.byCountry = [];
            this.filters.byOffer = [];
            this.filters.byStatus = '';
            this.filters.byGuid = '';
            this.filters.byUtmContent = '';
            this.filters.byUtmMedium = '';
            this.filters.byUtmCampaign = '';
            this.filters.byUtmTerm = '';
            this.$emit('update:filters', {...this.filters, dateRange: {startDate: dateRange.startDate, endDate: dateRange.endDate}});
        },
        setDateRange(dateRange) {
            this.$emit('update:filters', {...this.filters, dateRange: {startDate: dateRange.startDate, endDate: dateRange.endDate}});
        },
        showCustomizeTable(){
            this.$emit('customizeTable')
        }
    },
    beforeMount() {
        this.applyDateRangeFromUrl();
    },
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.StatSessionFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    & > div {
        flex: none;
        margin-right: 15px;
        margin-bottom: 40px;
        @include break(md) {
            width: calc(50% - 15px) !important;
            &:nth(2n) {
                margin-right: 0;
            }
        }
        @include break(lg) {
            width: calc(33.33% - 15px) !important;
            &:nth(3n) {
                margin-right: 0;
            }
        }
        @include break(xl) {
            width: calc(25% - 15px) !important;
            &:nth(4n) {
                margin-right: 0;
            }
        }

    }
    &__reset {

    }
}
</style>